.app-container {
  display: flex;
  height: 90vh;
  gap: 20px;
  padding: 20px;
}

.left-panel,
.right-panel {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.left-panel {
  width: 45%;
}

.right-panel {
  width: 55%;
}

/* Utility classes */
.font-quicksand {
  font-family: "Quicksand", sans-serif;
}

/* Updated brainstorm space styles */
.brainstorm-space {
  height: 60%;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
  min-height: 300px; /* Adjust as needed */
}

.brainstorm-segments-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  flex-grow: 1;
  margin-bottom: 20px;
}

/* Updated lesson builder space styles */
.lesson-builder-space {
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  overflow-y: auto;
  position: relative;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.generate-more-btn,
.build-lesson-btn {
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* ... rest of the existing styles */

/* Updated segment card styles */
.segment-card {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  cursor: move;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
}

.lesson-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  padding: 5px;
  border: 1px dashed #ccc;
  border-radius: 8px;
  min-height: 50px;
  transition: all 0.3s ease;
}

.lesson-row.editing-row {
  width: calc(100% + 200px);
  margin-left: -100px;
  padding-left: 105px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  position: relative;
}

/* ... (previous styles remain the same) */

.lesson-segment {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  margin: 5px;
  cursor: move;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex: 1;
  min-width: 150px;
  transition: all 0.3s ease;
  overflow: hidden;
  position: relative;
}

.lesson-segment.editing {
  flex: 0 0 calc(100% - 20px);
  cursor: text;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.lesson-segment textarea {
  width: 100%;
  height: 100%;
  min-height: 100px;
  border: none;
  resize: vertical;
  font-family: inherit;
  font-size: inherit;
  padding: 0;
  margin: 0;
}

.lesson-segment .react-markdown {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

/* ... (rest of the styles remain the same) */

.drop-zone.new-row {
  border: 2px dashed #ccc;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  color: #999;
  margin-top: 10px;
}

.ghost-segment {
  opacity: 0.5;
  background-color: #e9ecef;
  border: 2px dashed #007bff;
}

/* Add subtle drop shadows to components */
.lesson-row,
.lesson-segment {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.segment-card.expanded {
  grid-column: 1 / -1;
}

.segment-card h3 {
  margin-top: 0;
  margin-bottom: 10px;
}

.segment-card p {
  margin: 0;
}

.coach-related-badge {
  background-color: #4caf50;
  color: white;
  padding: 3px 8px;
  border-radius: 12px;
  font-size: 0.8em;
  align-self: flex-start;
  margin-top: 5px;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.page-indicator {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none;
  background-color: #ddd;
  margin: 0 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.page-indicator.active {
  background-color: #4caf50;
  color: white;
}

.page-indicator.new-content {
  background-color: #ffa500;
}

.generate-more-container {
  margin-top: 20px;
  text-align: center;
}

.generate-more-btn {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  transition: all 0.3s ease;
}

.generate-more-btn:hover {
  background-color: #45a049;
}

.generate-more-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.segment-card-wrapper {
  position: relative;
}

.segment-card {
  width: 100%;
  height: 100%;
}

.group-header {
  color: #888;
  font-size: 0.9em;
  margin-bottom: 5px;
}

.loading-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner p {
  margin-top: 20px;
  font-size: 16px;
  color: #333;
}

.lesson-builder-header {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 2rem;
  padding: 1rem;
  background-color: #f8f9fa;
  border-bottom: 3px solid #007bff;
}

.lesson-segment:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.delete-segment-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(220, 53, 69, 0.8);
  color: white;
  border: none;
  width: 24px;
  height: 24px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition:
    opacity 0.3s ease,
    background-color 0.3s ease;
}

.lesson-segment:hover .delete-segment-btn {
  opacity: 1;
}

.delete-segment-btn:hover {
  background-color: rgba(220, 53, 69, 1);
}

.lesson-segment h3 {
  margin-top: 0;
  padding-right: 30px; /* Make room for the delete button */
}

.lesson-plan-viewer {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.lesson-plan-title {
  font-size: 2rem;
  color: #333;
  margin-bottom: 1.5rem;
  text-align: center;
}

.lesson-plan-content {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  margin-bottom: 2rem;
}

.lesson-plan-content h1,
.lesson-plan-content h2,
.lesson-plan-content h3,
.lesson-plan-content h4,
.lesson-plan-content h5,
.lesson-plan-content h6 {
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  color: #333;
}

.lesson-plan-content p {
  margin-bottom: 1em;
  line-height: 1.6;
}

.lesson-plan-content ul,
.lesson-plan-content ol {
  margin-bottom: 1em;
  padding-left: 2em;
}

.lesson-plan-content li {
  margin-bottom: 0.5em;
}

.lesson-plan-content blockquote {
  border-left: 4px solid #007bff;
  padding-left: 1em;
  margin-left: 0;
  color: #666;
}

.lesson-plan-content code {
  background-color: #f0f0f0;
  padding: 0.2em 0.4em;
  border-radius: 3px;
  font-family: monospace;
}

.lesson-plan-actions {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.action-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.save-btn {
  background-color: #28a745;
  color: white;
}

.save-btn:hover {
  background-color: #218838;
}

.email-btn {
  background-color: #007bff;
  color: white;
}

.email-btn:hover {
  background-color: #0056b3;
}

.back-btn {
  background-color: #6c757d;
  color: white;
}

.back-btn:hover {
  background-color: #545b62;
}
